import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Estate } from "../types/Estate";
import PhotoCarousel from "./columns/PhotoCarousel";
import React, { ReactElement } from "react";

import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeckIcon from "@mui/icons-material/Deck";
import PoolIcon from "@mui/icons-material/Pool";
import BalconyIcon from "@mui/icons-material/Balcony";
import YardIcon from "@mui/icons-material/Yard";
import ExploreIcon from "@mui/icons-material/Explore";
import SpaIcon from "@mui/icons-material/Spa";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import VrpanoIcon from "@mui/icons-material/Vrpano";
import GarageIcon from "@mui/icons-material/Garage";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import ElevatorIcon from "@mui/icons-material/Elevator";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import SecurityUpdateGoodIcon from "@mui/icons-material/SecurityUpdateGood";
import { mapKeys } from "lodash";
import { headers } from "../data/headers";
interface OverviewProps {
  estate?: Estate;
  onClose: () => void;
  open: boolean;
}

interface Specification {
  icon: ReactElement;
  title: string;
}

interface Specifications {
  [category: string]: Specification[];
}

export default function Overview({ estate, onClose, open }: OverviewProps) {
  if (!estate) return <></>;

  const floor = estate["Etage"] !== "0" ? estate["NB d’étages"] : "1";
  const maxFloors = estate["NB d’étages"] !== "0" ? estate["NB d’étages"] : "-";

  const globalSpecifications = getGlobalSpecifications(estate);
  const specifications = getSpecifications(estate) as Specifications;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>
        <Box sx={{ width: "60%", height: "520px", margin: "auto", mb: 4 }}>
          <PhotoCarousel legend estate={estate} height={520} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">{estate.Libellé}</Typography>
          <Typography variant="h5">
            {estate["Prix / Loyer / Prix de cession"]} €
          </Typography>
        </Box>
        <Typography variant="subtitle1">
          {estate["NB de pièces"]} pièces •{" "}
          {estate["NB de chambres"] !== "0" &&
            `${estate["NB de chambres"]} Chambres `}
          • {estate["Surface (m²)"]}
          {"m² "}• Etage {floor}/{maxFloors}{" "}
          {estate["NB balcons"] !== "" && "• Balcon "}
          {estate.Terrasse === "OUI" && "• Terrasse "}
          {estate["Si garage"] === "OUI" && "• Garage "}
          {estate["Ascenseur"] === "OUI" && "• Ascenseur "}
          {estate["NB de boxes"] !== "" && "• Box "}
          {estate.Piscine === "OUI" && "• Piscine "}
        </Typography>
        <Typography variant="subtitle1" color="HighlightText" sx={{ mt: 2 }}>
          Quartier
          {estate["Quartier / Proximité"] !== ""
            ? `${estate["Quartier / Proximité"]} `
            : ""}{" "}
          à{" "}
          <Typography component="span" color="primary">
            {estate.Ville} ({estate.CP})
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            {estate.Descriptif.split("<br>").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
          <List
            dense
            sx={{ m: 2, display: "flex", flexDirection: "row", padding: 0 }}
          >
            {globalSpecifications.map((specification, index) => (
              <ListItem key={index}>
                <ListItemIcon>{specification.icon}</ListItemIcon>
                <ListItemText>{specification.title}</ListItemText>
              </ListItem>
            ))}
          </List>
          <Grid container spacing={2}>
            {Object.keys(specifications).map((category) => (
              <React.Fragment key={category}>
                {specifications[category].length > 0 && (
                  <Grid item xs={3}>
                    <Typography variant="h5">{category}</Typography>
                    {specifications[category].map((specification, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>{specification.icon}</ListItemIcon>
                        <ListItemText>{specification.title}</ListItemText>
                      </ListItem>
                    ))}
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </Typography>
        <div style={{whiteSpace: "pre"}}>
          {JSON.stringify(mapKeys(estate, (value, key) => {
            return `${key} (index=${headers.indexOf(key)})`;
          }), null, 4)}
        </div>
      </DialogContent>
    </Dialog>
  );
}

function getGlobalSpecifications(estate: Estate) {
  const globalSpecifications = [] as Specification[];
  if (estate["Année de construction"] !== "") {
    globalSpecifications.push({
      icon: <LocalActivityIcon />,
      title: `Construit en ${estate["Année de construction"]}`,
    });
  }
  if (estate["Travaux à prévoir"] === "OUI") {
    globalSpecifications.push({
      icon: <EngineeringIcon />,
      title: `Travaux à prévoir`,
    });
  }
  if (estate["Date de disponibilité"] !== "") {
    globalSpecifications.push({
      icon: <CalendarMonthIcon />,
      title: `Disponible en ${estate["Date de disponibilité"]}`,
    });
  }

  return globalSpecifications;
}

function getSpecifications(estate: Estate) {
  const exteriorSpecifications = [];
  if (estate.Piscine === "OUI")
    exteriorSpecifications.push({ title: "Piscine", icon: <PoolIcon /> });
  if (
    estate["Surface terrain (m²)"] !== "" &&
    parseInt(estate["Surface terrain (m²)"]) > 0
  )
    exteriorSpecifications.push({ title: `Jardin`, icon: <YardIcon /> });
  if (estate.Terrasse === "OUI")
    exteriorSpecifications.push({
      title: `${estate["Nombre terrasses"]} Terrasse(s)`,
      icon: <DeckIcon />,
    });
  if (estate["NB balcons"] !== "" && estate["NB balcons"] !== "0")
    exteriorSpecifications.push({
      title: `${estate["NB balcons"]} Balcon(s)`,
      icon: <BalconyIcon />,
    });

  const orientations = getOrientations(estate);

  const situationSpecifications = [];
  if (orientations.length > 0)
    situationSpecifications.push({
      title: `Orientation ${orientations.join("/")}`,
      icon: <ExploreIcon />,
    });

  if (estate.Calme === "OUI")
    situationSpecifications.push({ title: `Calme`, icon: <SpaIcon /> });
  if (estate["Vue dégagée"] === "OUI")
    situationSpecifications.push({
      title: `Belle Vue`,
      icon: <WbTwilightIcon />,
    });
  if (estate["Vis-à-vis"] === "NON")
    situationSpecifications.push({
      title: `Sans vis à vis`,
      icon: <VrpanoIcon />,
    });

  const annexesSpecifications = [];
  if (estate["Si garage"] === "OUI")
    annexesSpecifications.push({
      title: `Garage`,
      icon: <GarageIcon />,
    });
  if (estate.Cave === "OUI")
    annexesSpecifications.push({ title: `Cave`, icon: <MeetingRoomIcon /> });
  if (parseInt(estate["NB de boxes"]) > 0)
    annexesSpecifications.push({
      title: `${estate["NB de boxes"]} Box(es)`,
      icon: <WarehouseIcon />,
    });
  if (parseInt(estate["NB de parkings"]) > 0)
    annexesSpecifications.push({
      title: `${estate["NB de parkings"]} Parking(s)`,
      icon: <LocalParkingIcon />,
    });

  const serviceSpecifications = [];

  if (estate.Ascenseur === "OUI") {
    serviceSpecifications.push({ title: "Ascenseur", icon: <ElevatorIcon /> });
  }
  if (estate.Gardien === "OUI") {
    serviceSpecifications.push({ title: "Gardien", icon: <RoomServiceIcon /> });
  }
  if (estate.Digicode === "OUI") {
    serviceSpecifications.push({
      title: "Digicode",
      icon: <SecurityUpdateGoodIcon />,
    });
  }
  if (estate.Interphone === "OUI") {
    serviceSpecifications.push({
      title: "Interphone",
      icon: <PhoneForwardedIcon />,
    });
  }

  return {
    "Cadre et situation": situationSpecifications,
    Extérieur: exteriorSpecifications,
    "Services et accessibilité": serviceSpecifications,
    "Surfaces annexes": annexesSpecifications,
  };
}

function getOrientations(estate: Estate) {
  const expositions = [];
  if (estate["Orientation ouest"] !== "") expositions.push("Ouest");
  if (estate["Orientation sud"] !== "") expositions.push("Sud");
  if (estate["Orientation est"] !== "") expositions.push("Est");

  return expositions;
}
