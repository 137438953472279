import { GridColDef } from "@mui/x-data-grid";
import { Estate } from "../../types/Estate";
import OfferType from "./OfferType";
import Label from "./Label";
import EstateType from "./EstateType";
import PhotoCarousel from "./PhotoCarousel";
import Location from "./Location";
import Specifications from "./Specifications";
import { Box } from "@mui/material";

export const columns: GridColDef<Estate>[] = [
  { field: "Référence agence du bien", headerName: "Référence", width: 80 },
  {
    field: "Type d’annonce",
    headerName: "Type d’annonce",
    width: 120,
    renderCell: (params) => <OfferType type={params.row["Type d’annonce"]} />,
  },
  {
    field: "Type de bien",
    headerName: "Type de bien",
    width: 120,
    renderCell: (params) => <EstateType type={params.row["Type de bien"]} />,
  },
  {
    field: "Photo 1",
    headerName: "Photos",
    width: 380,
    sortable: false,
    filterable: false,
    renderCell: (params) => (
      <Box sx={{ height: 300, width: 380, p: 1, my: 2 }}>
        <PhotoCarousel estate={params.row} height={300} />
      </Box>
    ),
  },
  {
    field: "Libellé",
    headerName: "Libellé",
    width: 180,
    renderCell: (params) => <Label label={params.row.Libellé} />,
  },
  {
    field: "Ville",
    headerName: "Ville",
    width: 100,
    renderCell: (params) => (
      <Location
        city={params.row.Ville}
        zip={params.row.CP}
        area={params.row["Quartier / Proximité"]}
      />
    ),
  },
  {
    field: "Prix / Loyer / Prix de cession",
    headerName: "Prix / Loyer",
    width: 100,
    valueGetter: (params) =>
      `${params.row["Prix / Loyer / Prix de cession"]} €`,
  },
  {
    field: "Surface (m²)",
    headerName: "Surface",
    width: 100,
    valueGetter: (params) => `${params.row["Surface (m²)"]} m²`,
  },
  {
    field: "NB de pièces",
    headerName: "Nombre pièces",
    width: 120,
  },
  {
    field: "NB de chambres",
    headerName: "Spécifications",
    width: 100,
    renderCell: (params) => <Specifications estate={params.row} />,
  },
];
