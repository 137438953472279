import { Box } from "@mui/material";
import { DataGrid, GridRowParams, GridToolbar } from "@mui/x-data-grid";
import { columns } from "./columns/columns";
import { Estate } from "../types/Estate";
import React, { useState } from "react";
import Overview from "./Overview";

interface ListingProps {
  estates: Estate[];
}

export default function Listing({ estates }: ListingProps) {
  const [displayedEstate, setDisplayedEstate] = useState<Estate | undefined>(
    undefined
  );

  const handleRowClick = (params: GridRowParams<Estate>) => {
    setDisplayedEstate(params.row);
  };

  const handleClose = () => {
    setDisplayedEstate(undefined);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        onRowClick={handleRowClick}
        slots={{
          toolbar: GridToolbar,
        }}
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}
        getRowHeight={() => "auto"}
        autoHeight
        rows={estates}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        disableRowSelectionOnClick
        pageSizeOptions={[5, 10, 25]}
      />
      <Overview
        open={displayedEstate !== null}
        onClose={handleClose}
        estate={displayedEstate}
      />
    </Box>
  );
}
