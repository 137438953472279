import { createTheme } from "@mui/material";
import { frFR } from "@mui/material/locale";
import { frFR as dataGridFrFr } from "@mui/x-data-grid";

export const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  frFR,
  dataGridFrFr
);
