import {
  Box,
  ButtonBase,
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Typography,
  styled,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Papa from "papaparse";
import { Estate } from "../types/Estate";
import { headers } from "../data/headers";

interface StyledDropZoneProps {
  drag: string;
}

const DropZone = styled(ButtonBase)<StyledDropZoneProps>(({ theme, drag }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  border: "2px dashed",
  borderColor:
    drag === "dragging" ? theme.palette.primary.light : theme.palette.grey[300],
  textAlign: "center",
  color: theme.palette.grey[500],
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,
  transition: "all 0.2s ease",
}));

interface UploadProps {
  onFileProcessed: (estates: Estate[]) => void;
}

export default function Upload({ onFileProcessed }: UploadProps) {
  const [processing, setProcessing] = useState(false);
  const [encoding, setEncoding] = useState("ISO-8859-1");

  console.log({encoding});

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      console.log({encoding});
      if (acceptedFiles && acceptedFiles.length > 0) {
        setProcessing(true);

        const acceptedFile = acceptedFiles[0];

        const reader = new FileReader();

        reader.onload = () => {
          const fileData = reader.result;

          // Parse the CSV file
          Papa.parse<any>(fileData as string, {
            header: false,
            delimiter: "!#",
            complete: (parsedData) => {
              // Build array of parsed CSV data with specification headers
              const csvData = parsedData.data.map((row, index) => {
                const rowData: any = {};
                headers.forEach((header, columnIndex) => {
                  // Assign values from CSV or empty string
                  rowData[header] = row[columnIndex] || "";
                });

                // Add an ID
                rowData.id = index;

                return rowData;
              });

              setProcessing(false);

              onFileProcessed(csvData);
              (window as any).keldomCsvData = csvData;
            },
          });
        };

        reader.readAsText(acceptedFile, encoding);
      }
    },
    [onFileProcessed, encoding]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: { "text/csv": [".csv"] },
    multiple: false,
  });

  return (
   <> <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Encoding</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={encoding}
        label="Encodage"
        onChange={(e) => setEncoding(e.target.value as string)}
      >
        <MenuItem value={"ISO-8859-1"}>Windows 1252</MenuItem>
        <MenuItem value={"UTF-8"}>UTF-8</MenuItem>
      </Select>
    </FormControl>
    <Paper sx={{ p: 2, height: "400px", width: "100%" }}>
      {processing ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DropZone {...getRootProps()} drag={isDragActive ? "dragging" : ""}>
          <input {...getInputProps()} />
          <CloudUploadIcon
            sx={{
              mb: 2,
              fontSize: "6rem",
              color: (theme) => {
                if (isDragAccept) {
                  return theme.palette.success.main;
                }
                if (isDragReject) {
                  return theme.palette.error.main;
                }
              },
            }}
          />
          <Typography variant="h4">
            Charger fichier <b>Annonces.csv</b>
          </Typography>
          <Typography variant="caption">
            Glisser déposer ou <Link>Explorer</Link>
          </Typography>
        </DropZone>
      )}
    
    </Paper></>
  );
}
