import { Estate } from "../../types/Estate";

interface SpecificationsProps {
  estate: Estate;
}

export default function Specifications({ estate }: SpecificationsProps) {
  const floor = estate["Etage"] !== "0" ? estate["NB d’étages"] : "1";
  const maxFloors = estate["NB d’étages"] !== "0" ? estate["NB d’étages"] : "-";

  return (
    <ul style={{ paddingInlineStart: 0 }}>
      {estate["NB de chambres"] !== "0" && (
        <li>{estate["NB de chambres"]} Chambres</li>
      )}
      <li>
        Etage {floor}/{maxFloors}
      </li>
      {estate["NB balcons"] !== "" && <li>Balcon</li>}
      {estate.Terrasse === "OUI" && <li>Terrasse</li>}
      {estate["Si garage"] === "OUI" && <li>Garage</li>}
      {estate["Ascenseur"] === "OUI" && <li>Ascenseur</li>}
      {estate["NB de boxes"] !== "" && <li>Box</li>}
      {estate.Piscine === "OUI" && <li>Piscine</li>}
    </ul>
  );
}
