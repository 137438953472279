import { Container, ThemeProvider } from "@mui/material";
import React, { useState } from "react";
import Upload from "./components/Upload";
import { Estate } from "./types/Estate";
import Listing from "./components/Listing";
import { theme } from "./theme";

function App() {
  const [estates, setEstates] = useState<Estate[]>([]);

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ pt: 4 }} maxWidth="xl">
        {estates.length > 0 ? (
          <Listing estates={estates} />
        ) : (
          <Upload onFileProcessed={(estatesData) => setEstates(estatesData)} />
        )}
      </Container>
    </ThemeProvider>
  );
}

export default App;
