import { Box } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { headers } from "../../data/headers";
import { Estate } from "../../types/Estate";

const PHOTO_FIELD_REGEX = /^Photo \d+$/;

const PHOTO_TITLE_FIELD_SUFFIX = "Titre photo ";

const photoKeys = headers.filter((header) => PHOTO_FIELD_REGEX.test(header));

interface PhotosProps {
  estate: Estate;
  height: number;
  legend?: boolean;
}

export default function PhotoCarousel({ estate, height, legend }: PhotosProps) {
  const photos = photoKeys
    .map((photoKey) => estate[photoKey as keyof Estate])
    .filter((photo) => photo !== "")
    .map((photo, index) => ({
      img: photo,
      label: estate[(PHOTO_TITLE_FIELD_SUFFIX + index) as keyof Estate],
    }));

  return (
    <Carousel
      height={height + 4}
      autoPlay={false}
      indicatorContainerProps={{
        style: {
          zIndex: 1,
          marginTop: "-48px",
          position: "relative",
          marginBottom: "24px",
        },
      }}
      navButtonsAlwaysVisible={legend}
      indicators={!legend}
    >
      {photos.map((photo, index) => (
        <Box
          key={index}
          component="img"
          sx={{
            height,
            width: "100%",
            borderRadius: "8px",
            overflow: "hidden",
          }}
          src={photo.img}
          alt={photo.label}
        />
      ))}
    </Carousel>
  );
}
