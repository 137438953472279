export const headers = [
  "Identifiant agence",
  "Référence agence du bien",
  "Type d’annonce",
  "Type de bien",
  "CP",
  "Ville",
  "Pays",
  "Adresse",
  "Quartier / Proximité",
  "Activités commerciales",
  "Prix / Loyer / Prix de cession",
  "Loyer / mois murs",
  "Loyer CC",
  "Loyer HT",
  "Honoraires",
  "Surface (m²)",
  "Surface terrain (m²)",
  "NB de pièces",
  "NB de chambres",
  "Libellé",
  "Descriptif",
  "Date de disponibilité",
  "Charges",
  "Etage",
  "NB d’étages",
  "Meublé",
  "Année de construction",
  "Refait à neuf",
  "NB de salles de bain",
  "NB de salles d’eau",
  "NB de WC",
  "WC séparés",
  "Type de chauffage",
  "Type de cuisine",
  "Orientation sud",
  "Orientation est",
  "Orientation ouest",
  "Orientation nord",
  "NB balcons",
  "SF Balcon",
  "Ascenseur",
  "Cave",
  "NB de parkings",
  "NB de boxes",
  "Digicode",
  "Interphone",
  "Gardien",
  "Terrasse",
  "Prix semaine Basse Saison",
  "Prix quinzaine Basse Saison",
  "Prix mois / Basse Saison",
  "Prix semaine Haute Saison",
  "Prix quinzaine Haute Saison",
  "Prix mois Haute Saison",
  "NB de personnes",
  "Type de résidence",
  "Situation",
  "NB de couverts",
  "NB de lits doubles",
  "NB de lits simples",
  "Alarme",
  "Câble TV",
  "Calme",
  "Climatisation",
  "Piscine",
  "Aménagement pour handicapés",
  "Animaux acceptés",
  "Cheminée",
  "Congélateur",
  "Four",
  "Lave-vaisselle",
  "Micro-ondes",
  "Placards",
  "Téléphone",
  "Proche lac",
  "Proche tennis",
  "Proche pistes de ski",
  "Vue dégagée",
  "Chiffre d’affaire",
  "Longueur façade (m)",
  "Duplex",
  "Publications",
  "Mandat en exclusivité",
  "Coup de cœur",
  "Photo 1",
  "Photo 2",
  "Photo 3",
  "Photo 4",
  "Photo 5",
  "Photo 6",
  "Photo 7",
  "Photo 8",
  "Photo 9",
  "Titre photo 1",
  "Titre photo 2",
  "Titre photo 3",
  "Titre photo 4",
  "Titre photo 5",
  "Titre photo 6",
  "Titre photo 7",
  "Titre photo 8",
  "Titre photo 9",
  "Photo panoramique",
  "URL visite virtuelle",
  "Téléphone à afficher",
  "Contact à afficher",
  "Email de contact",
  "CP Réel du bien",
  "Ville réelle du bien",
  "Inter-cabinet",
  "Inter-cabinet prive",
  "N° de mandat",
  "Date mandat",
  "Nom mandataire",
  "Prénom mandataire",
  "Raison sociale mandataire",
  "Adresse mandataire",
  "CP mandataire",
  "Ville mandataire",
  "Téléphone mandataire",
  "Commentaires mandataire",
  "Commentaires privés",
  "Code négociateur",
  "Code Langue 1",
  "Proximité Langue 1",
  "Libellé Langue 1",
  "Descriptif Langue 1",
  "Code Langue 2",
  "Proximité Langue 2",
  "Libellé Langue 2",
  "Descriptif Langue 2",
  "Code Langue 3",
  "Proximité Langue 3",
  "Libellé Langue 3",
  "Descriptif Langue 3",
  "Champ personnalisé 1",
  "Champ personnalisé 2",
  "Champ personnalisé 3",
  "Champ personnalisé 4",
  "Champ personnalisé 5",
  "Champ personnalisé 6",
  "Champ personnalisé 7",
  "Champ personnalisé 8",
  "Champ personnalisé 9",
  "Champ personnalisé 10",
  "Champ personnalisé 11",
  "Champ personnalisé 12",
  "Champ personnalisé 13",
  "Champ personnalisé 14",
  "Champ personnalisé 15",
  "Champ personnalisé 16",
  "Champ personnalisé 17",
  "Champ personnalisé 18",
  "Champ personnalisé 19",
  "Champ personnalisé 20",
  "Champ personnalisé 21",
  "Champ personnalisé 22",
  "Champ personnalisé 23",
  "Champ personnalisé 24",
  "Champ personnalisé 25",
  "Dépôt de garantie",
  "Récent",
  "Travaux à prévoir",
  "Photo 10",
  "Photo 11",
  "Photo 12",
  "Photo 13",
  "Photo 14",
  "Photo 15",
  "Photo 16",
  "Photo 17",
  "Photo 18",
  "Photo 19",
  "Photo 20",
  "Identifiant technique",
  "Consommation énergie",
  "Bilan consommation énergie",
  "Emissions GES",
  "Bilan émission GES",
  "Identifiant quartier (obsolète)",
  "Sous type de bien",
  "Périodes de disponibilité",
  "Périodes basse saison",
  "Périodes haute saison",
  "Prix du bouquet",
  "Rente mensuelle",
  "Age de l’homme",
  "Age de la femme",
  "Entrée",
  "Résidence",
  "Parquet",
  "Vis-à-vis",
  "Transport : Ligne",
  "Transport : Station",
  "Durée bail",
  "Places en salle",
  "Monte-charge",
  "Quai",
  "Nombre de bureaux",
  "Prix du droit d’entrée",
  "Prix masqué",
  "Loyer annuel global",
  "Charges annuelles globales",
  "Loyer annuel au m2",
  "Charges annuelles au m2",
  "Charges mensuelles HT",
  "Loyer annuel CC",
  "Loyer annuel HT",
  "Charges annuelles HT",
  "Loyer annuel au m2 CC",
  "Loyer annuel au m2 HT",
  "Charges annuelles au m2 HT",
  "Divisible",
  "Surface divisible minimale",
  "Surface divisible maximale",
  "Surface séjour",
  "Nombre de véhicules",
  "Prix du droit au bail",
  "Valeur à l’achat",
  "Répartition du chiffre d’affaire",
  "Terrain agricole",
  "Equipement bébé",
  "Terrain constructible",
  "Résultat Année N-2",
  "Résultat Année N-1",
  "Résultat Actuel",
  "Immeuble de parkings",
  "Parking isolé",
  "Si Viager Vendu Libre",
  "Logement à disposition",
  "Terrain en pente",
  "Plan d’eau",
  "Lave-linge",
  "Sèche-linge",
  "Connexion internet",
  "Chiffre affaire Année N-2",
  "Chiffre affaire Année N-1",
  "Conditions financières",
  "Prestations diverses",
  "Longueur façade",
  "Montant du rapport",
  "Nature du bail",
  "Nature bail commercial",
  "Nombre terrasses",
  "Prix hors taxes",
  "Si Salle à manger",
  "Si Séjour",
  "Terrain donne sur la rue",
  "Immeuble de type bureaux",
  "Terrain viabilisé",
  "Equipement Vidéo",
  "Surface de la cave",
  "Surface de la salle à manger",
  "Situation commerciale",
  "Surface maximale d’un bureau",
  "Honoraires charge acquéreur (obsolète)",
  "Pourcentage honoraires TTC (obsolète)",
  "En copropriété",
  "Nombre de lots",
  "Charges annuelles",
  "Syndicat des copropriétaires en procédure",
  "Détail procédure du syndicat des copropriétaires",
  "Champ personnalisé 26",
  "Photo 21",
  "Photo 22",
  "Photo 23",
  "Photo 24",
  "Photo 25",
  "Photo 26",
  "Photo 27",
  "Photo 28",
  "Photo 29",
  "Photo 30",
  "Titre photo 10",
  "Titre photo 11",
  "Titre photo 12",
  "Titre photo 13",
  "Titre photo 14",
  "Titre photo 15",
  "Titre photo 16",
  "Titre photo 17",
  "Titre photo 18",
  "Titre photo 19",
  "Titre photo 20",
  "Titre photo 21",
  "Titre photo 22",
  "Titre photo 23",
  "Titre photo 24",
  "Titre photo 25",
  "Titre photo 26",
  "Titre photo 27",
  "Titre photo 28",
  "Titre photo 29",
  "Titre photo 30",
  "Prix du terrain",
  "Prix du modèle de maison",
  "Nom de l'agence gérant le terrain",
  "Latitude",
  "Longitude",
  "Précision GPS",
  "Version Format",
  "Honoraires à la charge de",
  "Prix hors honoraires acquéreur",
  "Modalités charges locataire",
  "Complément loyer",
  "Part honoraires état des lieux",
  "URL du Barème des honoraires de l’Agence",
  "Prix minimum",
  "Prix maximum",
  "Surface minimale",
  "Surface maximale",
  "Nombre de pièces minimum",
  "Nombre de pièces maximum",
  "Nombre de chambres minimum",
  "Nombre de chambres maximum",
  "ID type étage",
  "Si combles aménageables",
  "Si garage",
  "ID type garage",
  "Si possibilité mitoyenneté",
  "Surface terrain nécessaire",
  "Localisation",
  "Nom du modèle",
  "Date réalisation DPE",
  "Version DPE",
  "DPE coût min conso",
  "DPE coût max conso",
  "DPE Année de référence conso",
  "Surface terrasse",
  "DPE coût conso annuelle",
  "Loyer de base",
  "Loyer de référence majoré",
  "Encadrement des loyers",
];
