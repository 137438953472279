import { Typography } from "@mui/material";

interface LabelProps {
  label: string;
}

export default function Label({ label }: LabelProps) {
  return (
    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
      {label}
    </Typography>
  );
}
