import { Box, Typography } from "@mui/material";

interface LocationProps {
  city: string;
  zip: string;
  area: string;
}

export default function Location({ city, zip, area }: LocationProps) {
  return (
    <Box>
      <Typography variant="h6">
        {city} ({zip})
      </Typography>
      <Typography variant="caption">{area}</Typography>
    </Box>
  );
}
